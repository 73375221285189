import request from '@/utils/request'

interface IEnumsParams{
    enums:Array<string>
}
// 获取枚举
export const enums = (params:IEnumsParams): Promise<any> => {
    return request.get('/common/enums',{params})
}

// 获取日志
export const logs = (objectName:string,objectId:string,params:any): Promise<any> => {
    return request.get(`/web/${objectName}/${objectId}/logs`,{params})
}

// 上传文件
export const uploadFile = (params:any)=>{
    return request.post('/common/file/upload',params)
}

// 创建分类
export const addCategorie = (params: any): Promise<any> => {
    return request.post('/web/categories',params)
}
// 分类列表
export const getCategories = (params?: any): Promise<any> => {
    return request.get('/web/categories', { params })
}
// 编辑分类
export const editCategorie = (params?: any): Promise<any> => {
    return request.post(`/web/categories/edit`,params)
}

// 获取系统配置
export const getSystemConfig = (name: string): Promise<any> => {
    return request.get(`/web/config/${name}`,)
}
// 系统配置
export const systemConfig = (name:string,params?: any): Promise<any> => {
    return request.post(`/web/config/${name}`,params)
}
export default {
  path: '/web',
  name: 'web',
  redirect: '/web/workbench',
  component: () => import('@/views/web/layout.vue'),
  children: [
    // {
    //   path: '/web/test',
    //   name: 'test',
    //   component: () => import('@/views/web/test.vue'),
    //   meta: {
    //     title: '选人组件开发',
    //     isClose: false, // 顶部是否显示关闭按钮
    //     isBreadcrumb: false // 顶部是否显示导航路径
    //   }
    // },
    {
      path: 'workbench',
      name: 'workbench',
      component: () => import('@/views/web/workbench/index.vue'),
      meta: {
        title: '工作台',
        isClose: false, // 顶部是否显示关闭按钮
        isBreadcrumb: false // 顶部是否显示导航路径
      }
    },
    {
      path: 'data-helper',
      name: 'dataHelper',
      component: () => import('@/views/web/data/index.vue'),
      meta: {
        title: 'CEO 的十张表',
        isClose: false, // 顶部是否显示关闭按钮
        isBreadcrumb: false // 顶部是否显示导航路径
      }
    },
    {
      path: 'helper',
      name: 'helper',
      component: () => import('@/views/web/helper.vue'),
      meta: {
        title: '小助手',
        isClose: false, // 顶部是否显示关闭按钮
        isBreadcrumb: false // 顶部是否显示导航路径
      }
    },
    {
      path: '/web',
      redirect: '/web/center',
      meta: {
        title: '应用中心',
        isClose: false, // 顶部是否显示关闭按钮
        isBreadcrumb: false // 顶部是否显示导航路径
      },
      children: [
        {
          path: '/web/center',
          name: 'center',
          component: () => import('@/views/web/center/index.vue'),
          meta: {
            title: '应用中心',
            isClose: false, // 顶部是否显示关闭按钮
            isBreadcrumb: false // 顶部是否显示导航路径
          }
        },
        {
          path: '/web/work',
          redirect: '/web/work',
          meta: {
            icon: 'work-center',
            title: '工单中心',
            isClose: true, // 顶部是否显示关闭按钮
            isBreadcrumb: true // 顶部是否显示导航路径
          },
          children: [
            {
              path: '/web/work',
              name: 'work',
              component: () => import('@/views/web/center/work/index.vue'),
              meta: {
                icon: 'work-center',
                title: '工单中心',
                isClose: true, // 顶部是否显示关闭按钮
                isBreadcrumb: true // 顶部是否显示导航路径
              }
            },
            {
              path: '/web/work/create-work',
              name: 'createWork',
              component: () => import('@/views/web/center/work/create-work.vue'),
              meta: {
                icon: 'work-center',
                title: '工单中心',
                isClose: true, // 顶部是否显示关闭按钮
                isBreadcrumb: true // 顶部是否显示导航路径
              }
            },
            {
              path: '/web/work/work-info',
              name: 'workInfo',
              component: () => import('@/views/web/center/work/work-info.vue'),
              meta: {
                icon: 'work-center',
                title: '工单中心',
                isClose: true, // 顶部是否显示关闭按钮
                isBreadcrumb: true // 顶部是否显示导航路径
              }
            },
            {
              path: '/web/work/leader-info',
              name: 'leaderInfo',
              component: () => import('@/views/web/center/work/leader-info.vue'),
              meta: {
                icon: 'work-center',
                title: '工单中心',
                isClose: true, // 顶部是否显示关闭按钮
                isBreadcrumb: true // 顶部是否显示导航路径
              }
            }
          ]
        },
        {
          path: '/web/task',
          redirect: '/web/task',
          meta: {
            icon: 'task-center',
            title: '任务中心',
            isClose: true, // 顶部是否显示关闭按钮
            isBreadcrumb: true // 顶部是否显示导航路径
          },
          children: [
            {
              path: '/web/task',
              name: 'task',
              component: () => import('@/views/web/center/task/index.vue'),
              meta: {
                icon: 'task-center',
                title: '任务中心',
                isClose: true, // 顶部是否显示关闭按钮
                isBreadcrumb: true // 顶部是否显示导航路径
              }
            },
            {
              path: '/web/task-info',
              name: 'taskInfo',
              component: () => import('@/views/web/center/task/task-info.vue'),
              meta: {
                icon: 'task-center',
                title: '任务中心',
                isClose: true, // 顶部是否显示关闭按钮
                isBreadcrumb: true // 顶部是否显示导航路径
              }
            }
          ]
        },
        {
          path: '/web/approval',
          redirect: '/web/approval',
          meta: {
            icon: 'approval',
            title: '审批中心',
            isClose: true, // 顶部是否显示关闭按钮
            isBreadcrumb: true // 顶部是否显示导航路径
          },
          children: [
            {
              path: '/web/approval',
              name: 'approval',
              component: () => import('@/views/web/center/approval/index.vue'),
              meta: {
                icon: 'approval',
                title: '审批中心',
                isClose: true, // 顶部是否显示关闭按钮
                isBreadcrumb: true // 顶部是否显示导航路径
              }
            },
            {
              path: '/web/approval/create-approval',
              name: 'createApproval',
              component: () => import('@/views/web/center/approval/create-approval.vue'),
              meta: {
                icon: 'approval',
                title: '审批中心',
                isClose: true, // 顶部是否显示关闭按钮
                isBreadcrumb: true // 顶部是否显示导航路径
              }
            },
            {
              path: '/web/approval/approval-info',
              name: 'approvalInfo',
              component: () => import('@/views/web/center/approval/approval-info.vue'),
              meta: {
                icon: 'approval',
                title: '审批中心',
                isClose: true, // 顶部是否显示关闭按钮
                isBreadcrumb: true // 顶部是否显示导航路径
              }
            }
          ]
        }
      ]
    },
    {
      path: '/web/mine',
      name: 'mine',
      redirect: '/web/mine/info',
      meta: {
        title: '我的',
        isLeft: true,
        isClose: false, // 顶部是否显示关闭按钮
        isBreadcrumb: false // 顶部是否显示导航路径
      },
      children: [
        {
          path: 'info',
          name: 'mineInfo',
          component: () => import('@/views/web/mine/info.vue'),
          meta: {
            title: '我的信息',
            isClose: false, // 顶部是否显示关闭按钮
            isBreadcrumb: false // 顶部是否显示导航路径
          }
        },
        {
          path: 'password',
          name: 'minePassword',
          component: () => import('@/views/web/mine/password.vue'),
          meta: {
            title: '修改密码',
            isClose: false, // 顶部是否显示关闭按钮
            isBreadcrumb: false // 顶部是否显示导航路径
          }
        }
      ]
    },
    {
      path: '/web/message-list',
      name: 'messageList',
      component: () => import('@/views/web/message/message-list.vue'),
      meta: {
        title: '消息中心',
        isClose: true, // 顶部是否显示关闭按钮
        isBreadcrumb: false // 顶部是否显示导航路径
      }
    },
    {
      path: '/web/notice',
      name: 'notice',
      component: () => import('@/views/web/notice/index.vue'),
      meta: {
        icon: 'notice',
        title: '公告中心',
        isClose: true, // 顶部是否显示关闭按钮
        isBreadcrumb: true // 顶部是否显示导航路径
      }
    }
  ]
}

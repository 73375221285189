import request from '@/utils/request'

/**      物料库管理       */
// 物料列表
export const getMaterialTypes = (params?: any): Promise<any> => {
    return request.get('/web/materials', { params })
}

// 创建物料类
export const addMaterialType = (params: any): Promise<any> => {
    return request.post('/web/materials',params)
}

// 修改物料类
export const editMaterialType = (id:number,params?: any): Promise<any> => {
    return request.put(`/web/materials/${id}`,params)
}

// 删除物料类
export const deleteMaterialType = (id: number): Promise<any> => {
    return request.delete(`/web/materials/${id}`)
}

/**       物料信息管理     */

// 物料信息列表
export const getMaterials = (params?: any): Promise<any> => {
    return request.get('/web/material-inventories', { params })
}

// 创建物料
export const addMaterial = (params: any): Promise<any> => {
    return request.post('/web/material-inventories',params)
}

// 修改物料
export const editMaterial = (id:number,params?: any): Promise<any> => {
    return request.put(`/web/material-inventories/${id}`,params)
}

// 删除物料
export const deleteMaterial = (id: number): Promise<any> => {
    return request.delete(`/web/material-inventories/${id}`)
}
// 入库
export const inbound = (params: any): Promise<any> => {
    return request.post('/web/material-inventories',params)
}
// 批量入库
export const multInbound = (params: any): Promise<any> => {
    return request.post('/web/material-inventories/batch',params)
}

/**           仓库管理       */
// 创建/编辑仓库
export const editWarehouse = (params: any): Promise<any> => {
    return request.post('/web/warehouses',params)
}
// 仓库列表
export const warehouses = (): Promise<any> => {
    return request.get('/web/warehouses')
}
// 删除仓库
export const deleteWarehouse = (id: number): Promise<any> => {
    return request.delete(`/web/warehouses/${id}`)
}
import axios from 'axios'
import {ElMessage, ElMessageBox} from 'element-plus'
import cookie from '@/utils/cookie'
import router from '@/router'
import store from '@/store'
import {local,LOCAL_STORAGE} from '@/utils/storage'

const request = axios.create({
    timeout: 5000,
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_BASE_URL
})

const requestStack = {} // 请求栈
const requestStackWhiteList = ['/common/record'] // 请求栈白名单

/**
 * 删除请求栈
 * @param keyName
 */
const trashRequestStack = (keyName: string): void => {
    // eslint-disable-next-line no-prototype-builtins
    if (keyName && requestStack.hasOwnProperty(keyName)) {
        setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete requestStack[keyName]
        }, 1000)
    }
}

// Request拦截器
request.interceptors.request.use(
    config => {
        const token = local.get(LOCAL_STORAGE.token)
        // config.headers['language'] = localStorage.getItem('language') || 'zh_CN'
        if (token && config.headers) {
            config.headers['token'] = token
            config.headers['company'] = local.get(LOCAL_STORAGE.company).id
        }
        const url = config.url ? config.url : ''
        if (config.method?.toLowerCase() === 'post' && !requestStackWhiteList.includes(url)) { // 将post请求加入到请求栈
            const source = axios.CancelToken.source()
            config.cancelToken = source.token
            // eslint-disable-next-line no-prototype-builtins
            if (requestStack.hasOwnProperty(url)) { // 如果post请求栈还存在，提示重复请求
                source.cancel('Repeat the request')
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                requestStack[url] = source
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// Response拦截器
request.interceptors.response.use(
    response => {
        const url = response.config.url ? response.config.url : ''
        trashRequestStack(url)

        if (response.status === 200) {
            if (response.headers.token) {
                cookie.set('token', response.headers.token)
                local.set(LOCAL_STORAGE.token, response.headers.token);
            }
            return response.data
        }
    },
    error => {
        const url = error.config ? error.config.url : ''
        trashRequestStack(url)
        let message = error.message
        const status = error.response.status || 420
        if (error.code === 'ECONNABORTED') {
            message = '网络连接超时，请重试'
        }
        if (error.code === 'ERR_NETWORK') {
            message = '网络连接错误，请检查网络后重试'
        }
        if (error.code === 'ERR_BAD_RESPONSE' || error.code === 'ERR_BAD_REQUEST') {
            message = error.response.data?.message || ''
        }

        if (status === 420 || status === 500) {
            ElMessage({
                message,
                type: 'error',
                duration: status === 420 ? 3000 : 0,
                showClose: true,
                offset: 5
            })
        } else if (status === 401) {
            console.log('触发user/logout')
            store.dispatch('user/logout')
            if (!error.config.silence) {
                const path = encodeURIComponent(window.location.pathname)
                ElMessageBox.alert(message, '', {
                    confirmButtonText: 'ok',
                    callback: () => {
                        // cookie.del('token')
                        // cookie.del('user')
                        // local.remove(LOCAL_STORAGE.token)
                        // local.remove(LOCAL_STORAGE.user)
                        // local.remove(LOCAL_STORAGE.company)
                        // store.commit('setToken', '')
                        // store.commit('setUser', {})
                        // store.dispatch('user/logout').then(()=>{
                            router.push({path: '/login', query: {redirect:path}})
                        // })
                    }
                }).then(r => {
                    console.log(r)
                })
            }
        }
        return Promise.reject(error)
    }
)

export default request

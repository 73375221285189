import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dispatchEventStorage from './utils/locale'
import ElementPlus from 'element-plus'
import SvgIconPlugin from '@/plugins/SvgIconPlugin'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import locale from 'element-plus/lib/locale/lang/zh-cn' // 测试环境打包使用
import locale from 'element-plus/es/locale/lang/zh-cn' // 生产环境打包和本地使用
import {directive} from '@/directive'
import 'element-plus/dist/index.css'
import '@/assets/css/index.less'

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

directive(app);
app.use(store)
    .use(router)
    .use(ElementPlus, {locale})
    .use(SvgIconPlugin, {imports: []})
    .use(dispatchEventStorage)
    .mount('#app')

// 动态加载多语言文件 fix: 打包报错问题
// import(`element-plus/${process.env.NODE_ENV==='development'?'es':'lib'}/locale/lang/zh-cn`).then(module => {
//   console.log(module)
//   app.use(store)
//     .use(router)
//     .use(ElementPlus, {locale:module.default})
//     .use(SvgIconPlugin, {imports: []})
//     .mount('#app')
// })

